<template>
  <div v-if="order" class="layout-container py-32 sm:py-64 min-h-screen flex flex-col">
    <img
      :src="footer.logoUrl"
      class="w-112 lg:w-144 object-contain relative mx-auto mb-24 sm:mb-32"
    >
    <h1 class="text-center md:text-5xl md:mb-32">{{ translation.yourOrder }}</h1>
    <div class="grid sm:grid-cols-3 pt-12 sm:pt-48">
      <div v-if="order.customerName" class="mb-24 sm:mb-32">
        <div class="text-xsmall-medium sm:text-base-medium sm:text-lg mb-6 sm:mb-12">{{ translation.customer }}</div>
        <div class="text-xsmall sm:text-small-regular sm:text-md">{{ order.customerName }}</div>
      </div>
      <div v-if="order.orderNumber" class="mb-24 sm:mb-32">
        <div class="text-xsmall-medium sm:text-base-medium sm:text-lg mb-6 sm:mb-12">{{ translation.orderNumber }}</div>
        <div class="text-xsmall sm:text-small-regular sm:text-md">{{ order.orderNumber }}</div>
      </div>
      <div v-if="order.orderDate" class="mb-24 sm:mb-32">
        <div class="text-xsmall-medium sm:text-base-medium sm:text-lg mb-6 sm:mb-12">{{ translation.orderDate }}</div>
        <div class="text-xsmall sm:text-small-regular sm:text-md">{{ getLocaleDate(order.orderDate, { month: 'long', day: 'numeric', year: 'numeric' }) }}</div>
      </div>
      <div v-if="order.paymentMethod" class="mb-24 sm:mb-32">
        <div class="text-xsmall-medium sm:text-base-medium sm:text-lg mb-6 sm:mb-12">{{ translation.paymentMethod }}</div>
        <div class="text-xsmall sm:text-small-regular sm:text-md">{{ order.paymentMethod }}</div>
      </div>
      <div v-if="order.deliveryMethod" class="mb-24 sm:mb-32">
        <div class="text-xsmall-medium sm:text-base-medium sm:text-lg mb-6 sm:mb-12">{{ translation.deliveryMethod }}</div>
        <div class="text-xsmall sm:text-small-regular sm:text-md">{{ order.deliveryMethod }}</div>
      </div>
      <div v-if="order.zipCode" class="mb-24 sm:mb-32">
        <div class="text-xsmall-medium sm:text-base-medium sm:text-lg mb-6 sm:mb-12">{{ translation.deliveryAddress }}</div>
        <div class="text-xsmall sm:text-small-regular sm:text-md">{{ order.addressLine1 }}</div>
        <div class="text-xsmall sm:text-small-regular sm:text-md">{{ order.zipCode }} {{ order.city }}</div>
      </div>
    </div>
    <div class="flex mt-24 sm:mt-48">
      <div class="mb-8 sm:mb-12 w-1/2 pr-8 md:w-6/12 text-xsmall-medium sm:text-base-medium sm:text-lg">{{ translation.product }}</div>
      <div class="mb-8 sm:mb-12 w-1/12 md:w-2/12 text-xsmall-medium sm:text-base-medium sm:text-lg">{{ translation.quantity }}</div>
      <div class="mb-8 sm:mb-12 w-3/12 pl-24 md:w-3/12 text-xsmall-medium sm:text-base-medium sm:text-lg">{{ translation.price }}</div>
      <div class="mb-8 sm:mb-12 w-2/12 md:w-2/12 text-right text-xsmall-medium sm:text-base-medium sm:text-lg">{{ translation.total }}</div>
    </div>
    <div class="border-b border-black pb-12">
      <div
        v-for="(item, index) in order.items"
        :key="index"
        class="flex mb-8"
      >
        <div class="w-1/2 pr-8 md:w-6/12 text-xsmall sm:text-small-regular sm:text-md">{{ item.name }}</div>
        <div class="w-1/12 md:w-2/12 text-xsmall sm:text-small-regular sm:text-md">{{ item.quantity }}</div>
        <div class="w-3/12 pl-24 md:w-3/12 text-xsmall sm:text-small-regular sm:text-md">{{ item.unitPrice }} {{ currencySuffix }}</div>
        <div class="w-2/12 md:w-2/12 text-right text-xsmall sm:text-small-regular sm:text-md">{{ item.price }} {{ currencySuffix }}</div>
      </div>
    </div>
    <div class="flex items-end flex-col mt-16">
      <div v-if="order.orderType === OrderType.Online" class="text-xsmall sm:text-small-regular sm:text-md mb-8 flex w-144 justify-between">
        <div>{{ translation.shipping }}</div>
        <div>{{ order.shipping }} {{ currencySuffix }}</div>
      </div>
      <div class="text-xsmall-medium sm:text-base-medium sm:text-lg mb-8 flex w-144 justify-between">
        <div>{{ translation.total }}</div>
        <div>{{ order.orderTotal }} {{ currencySuffix }}</div>
      </div>
      <div class="text-xsmall sm:text-small-regular sm:text-md mb-8 flex w-auto justify-between">
        <div class="mr-16 sm:mr-24">{{ translation.vat }}</div>
        <div>{{ order.vat }} {{ currencySuffix }}</div>
      </div>
    </div>
    <div class="text-xsmall sm:text-small-regular mt-auto mb-32">
      <div class="mx-auto w-fit-content mt-32 text-center">
        <div class="mb-6">{{ marketSettings.company.name }}</div>
        <div class="mb-6">{{ marketSettings.company.address }}</div>
        <div class="mb-6">{{ marketSettings.company.phone }}</div>
      </div>
      <div class="sm:flex mt-24 sm:mt-32">
        <div class="mb-8 sm:w-1/3 sm:text-left">
          {{ translation.orgNumber }} {{ marketSettings.company.businessRegistrationNumber }}
        </div>
        <div class="mb-8 sm:w-1/3 sm:text-center">
          {{ marketSettings.company.website }}
        </div>
        <div class="mb-8 sm:w-1/3 sm:text-right">
          {{ translation.vatNumber }} {{ marketSettings.company.vatRegistrationNumber }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { ref } from 'vue';
import { type IOrder, OrderType } from '~/api-types';
import useLocalDate from '~/composables/useLocalDate';
const globalContentStore = useGlobalContentStore();
const translation = globalContentStore.myPageResources;
const marketSettings = globalContentStore.marketSettings;
const footer = useGlobalContentStore().footer;
const { getLocaleDate } = useLocalDate();
const { apiGet } = useApiFetch();

const route = useRoute();
const orderNumber = ref(route.query.orderNumber);
const orderType = ref(route.query.orderType);
const order = ref<IOrder>();

const currencySuffix = computed(() => {
  return marketSettings?.currency?.currencySuffix;
});

onMounted(()=> {
  nextTick(async()=> {
    getOrderData();
  });
});

const getOrderData = async() => {
  const query = `user/order?OrderNo=${orderNumber.value}&CountryCode=${marketSettings.countryCode}&orderType=${orderType.value}`;
  const res = await apiGet<IOrder>(query);
  order.value = res;
};

</script>
